/**
 * MENU
 */


export default function() {
    const burger = document.querySelector('.js-burger');
    const pane = document.querySelector('.nav-pane');

    if (!burger || !pane) return;

    burger.addEventListener('click', (e) => {
        if(burger.classList.contains('_open')) {
            burger.classList.remove('_open');
            pane.classList.remove('_open');
        } else {
            burger.classList.add('_open');
            pane.classList.add('_open');
        }
    })
}
