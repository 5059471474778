/**
 * App
 */

import 'Utils/closest.polyfill.js';
import 'Utils/matches.polyfill.js';

import menu from 'Components/menu';
import map from 'Components/map';
import forms from 'Components/forms';
import scrollTo from 'Components/scrollTo'

import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";

document.addEventListener('DOMContentLoaded', function() {
    window.Fancybox = Fancybox;

    Fancybox.bind('[data-fancybox="gallery"]', {
        Image: {
            zoom: false,
        },
    });

    menu();
    forms();
    scrollTo();

    // eslint-disable-next-line
    ymaps.ready(map);
});
