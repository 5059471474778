/**
 * Forms
 */

import JustValidate from 'just-validate';
import axios from 'axios';
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";

export default function() {

    const validateSender = new JustValidate('#sender-form', {
        errorLabelCssClass: 'form__error',
        errorFieldCssClass: '_error'
    });

    validateSender
        .addField('[name="name"]', [
            {
                rule: 'required',
                errorMessage: 'Обязательное поле',
            },
        ])
        .addField('[name="mail"]', [
            {
                rule: 'required',
                errorMessage: 'Обязательное поле',
            },
            {
                rule: 'email',
                errorMessage: 'Неверный Email!',
            },
        ])
        .onSuccess((e) => {
            e.preventDefault();
            const form = e.target;
            const fd = new FormData(form);
            //const success = document.querySelector('#success');
            //const text = success.querySelector('[data-text]');

            axios.post('/mail.php', fd)
                .then(function (response) {
                    form.reset();

                    //text.innerHTML = `<p>${response.data.data.message}</p>`;

                    Fancybox.close('all');

                    new Fancybox([
                        {
                            src: "#success",
                        },
                    ]);
                })
                .catch(function (error) {
                    console.log(error);
                });
    });

}