import gsap from 'gsap';
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);


export default function() {
	const scrollLinks = document.querySelectorAll('.js-scroll');

	if (scrollLinks.length === 0) return;

	scrollLinks.forEach(link => {
		link.addEventListener('click', (e) => {
			e.preventDefault();
			const href = link.getAttribute('href');

			gsap.to(window, {duration: 1, scrollTo: href});
		})
	})
}