/**
 * Navbar
 */
/* eslint-disable */

export default function() {
    const mapDiv = document.querySelector('.contacts-map__el')

    if (!mapDiv) return;

    const coords = mapDiv.dataset.coords;
    const coordsArr = coords.split(',');

    const myMap = new ymaps.Map(mapDiv, {
            center: coordsArr,
            zoom: 18
        }, {
            searchControlProvider: 'yandex#search'
        });

    myMap.geoObjects
        .add(new ymaps.Placemark(coordsArr, {
            balloonContent: ''
        }, {
            preset: 'islands#icon',
            iconColor: '#0095b6'
        }));
}
